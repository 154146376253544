#navi{
	position: absolute;
	background-color: rgba(95, 95, 95, 0.7);
	width: 100%;
	height: 71px;
	bottom:0;
	z-index: 10;
}
#navi .g-nav_flagship
{
	padding: 0;
}
#menu{
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 1058px;
	height: 27px;
	top: 13px;
	z-index: 10;
}


#visWrap {
	position: relative;
	width: 100%;
	height:787px;
	overflow: hidden;
	z-index: 1;
}

#vis1_box1,
#vis1_box2,
#vis1_box3,
#vis1_box4,
#vis1_box5,
#vis1_box6,
#vis1_box7,
#vis2_box1,
#vis2_box2,
#vis2_box3,
#vis2_box4,
#vis2_box5,
#vis2_box6,
#vis2_box7,
#vis3_box1,
#vis3_box2,
#vis3_box3,
#vis3_box4,
#vis3_box5,
#vis3_box6,
#vis3_box7 {
	background-color: #000000;
}
#vis1_box1 {
	position: absolute;
	overflow: hidden;
	left:0%;
	width:14.285714285714286%;
	height:100%;
}
#vis1_img1 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 0%;
	background-size:1920px;
	background-repeat: no-repeat;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis1_box2 {
	position: absolute;
	overflow: hidden;
	left:14.285714285714286%;
	width: 14.285714285714286%;
	height:100%;
}
#vis1_img2 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis1_box3 {
	position: absolute;
	overflow: hidden;
	left:28.571428571428571%;
	width: 14.285714285714286%;
	height:100%;
}
#vis1_img3 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis1_box4 {
	position: absolute;
	overflow: hidden;
	left:42.857142857142857%;
	width: 14.285714285714286%;
	height:100%;
}
#vis1_img4 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis1_box5 {
	position: absolute;
	overflow: hidden;
	left:57.142857142857143%;
	width: 14.285714285714286%;
	height:100%;
}
#vis1_img5 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis1_box6 {
	position: absolute;
	overflow: hidden;
	left:71.428571428571429%;
	width: 14.285714285714286%;
	height:100%;
}
#vis1_img6 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis1_box7 {
	position: absolute;
	overflow: hidden;
	left:85.714285714285714%;
	width: 14.285714285714286%;
	height:100%;
}
#vis1_img7 {
	background-image: url(../../images/alpineflagship/visual1.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}



#vis2_box1 {
	position: absolute;
	overflow: hidden;
	left:0%;
	width:14.285714285714286%;
	height:100%;
}
#vis2_img1 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 0%;
	background-size:1920px;
	background-repeat: no-repeat;
  position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis2_box2 {
	position: absolute;
	overflow: hidden;
	left:14.285714285714286%;
	width: 14.285714285714286%;
	height:100%;
}
#vis2_img2 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 20%;
	background-size:1920px;
  position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis2_box3 {
	position: absolute;
	overflow: hidden;
	left:28.571428571428571%;
	width: 14.285714285714286%;
	height:100%;
}
#vis2_img3 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 20%;
	background-size:1920px;
  position: absolute;
	opacity:0;
  width: 100%;
	height:100%;
	z-index: 1;
}

#vis2_box4 {
	position: absolute;
	overflow: hidden;
	left:42.857142857142857%;
	width: 14.285714285714286%;
	height:100%;
}
#vis2_img4 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 20%;
	background-size:1920px;
  position: absolute;
	opacity:0;
  width: 100%;
	height:100%;
	z-index: 1;
}

#vis2_box5 {
	position: absolute;
	overflow: hidden;
	left:57.142857142857143%;
	width: 14.285714285714286%;
	height:100%;
}
#vis2_img5 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 20%;
	background-size:1920px;
    position: absolute;
	opacity:0;
    width: 100%;
	height:100%;
	z-index: 1;
}

#vis2_box6 {
	position: absolute;
	overflow: hidden;
	left:71.428571428571429%;
	width: 14.285714285714286%;
	height:100%;
}
#vis2_img6 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis2_box7 {
	position: absolute;
	overflow: hidden;
	left:85.714285714285714%;
	width: 14.285714285714286%;
	height:100%;
}
#vis2_img7 {
	background-image: url(../../images/alpineflagship/visual2.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}



#vis3_box1 {
	position: absolute;
	overflow: hidden;
	left:0%;
	width:14.285714285714286%;
	height:100%;
}
#vis3_img1 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 0%;
	background-size:1920px;
	background-repeat: no-repeat;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis3_box2 {
	position: absolute;
	overflow: hidden;
	left:14.285714285714286%;
	width: 14.285714285714286%;
	height:100%;
}
#vis3_img2 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis3_box3 {
	position: absolute;
	overflow: hidden;
	left:28.571428571428571%;
	width: 14.285714285714286%;
	height:100%;
}
#vis3_img3 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis3_box4 {
	position: absolute;
	overflow: hidden;
	left:42.857142857142857%;
	width: 14.285714285714286%;
	height:100%;
}
#vis3_img4 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis3_box5 {
	position: absolute;
	overflow: hidden;
	left:57.142857142857143%;
	width: 14.285714285714286%;
	height:100%;
}
#vis3_img5 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis3_box6 {
	position: absolute;
	overflow: hidden;
	left:71.428571428571429%;
	width: 14.285714285714286%;
	height:100%;
}
#vis3_img6 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}

#vis3_box7 {
	position: absolute;
	overflow: hidden;
	left:85.714285714285714%;
	width: 14.285714285714286%;
	height:100%;
}
#vis3_img7 {
	background-image: url(../../images/alpineflagship/visual3.jpg);
	background-position: 20%;
	background-size:1920px;
	position: absolute;
	opacity:0;
	width: 100%;
	height:100%;
	z-index: 1;
}


#vis1_tx1{
	position: absolute;
	background-image:url(../../images/alpineflagship/F1logo.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-size:contain;
	top:525px;
	left: 635px;
	width:651px;
	height:85px;
	z-index: 10;
	opacity:0;
}
#vis1_tx2{
	position: absolute;
	color:#FFFFFF;
	font: normal 34.5px/150% 'Noto Serif JP', sans-serif;
	letter-spacing: 0.2em;
	text-align:center;
	top:568px;
	left:560px;
	width:800px;
	height:72px;
	z-index: 10;
	opacity:0;
}
#vis2_tx1{
	position: absolute;
	color:#FFFFFF;
	font: 500 italic 39px/100% 'Roboto';
	line-height: 39px;
	letter-spacing: 0.03em;
	text-align:left;
	top:442px;
	left:400px;
	width:657px;
	height:40px;
	z-index: 10;
	opacity:0;
	white-space: nowrap;
}
#vis2_tx2{
	position: absolute;
	color:#FFFFFF;
	font: 500 italic 39px/100% 'Roboto';
	line-height: 39px;
	letter-spacing: 0.03em;
	text-align:left;
	top:490px;
	left:400px;
	width:657px;
	height:40px;
	z-index: 10;
	opacity:0;
	white-space: nowrap;
}
#vis2_tx3{
	position: absolute;
	background-image:url("../../images/alpineflagship/perfect_logo.png");
	background-size: contain;
	background-repeat: no-repeat;
	left:400px;
	top:555px;
	width:657px;
	height:81px;
	z-index: 10;
	opacity:0;
}

#vis3_tx1{
	position: absolute;
	color:#000000;
	font: 500 italic 39px/100% 'Roboto';
	line-height: 39px;
	letter-spacing: 0.03em;
	text-align:left;
	top:88px;
	left:780px;
	width:820px;
	height:40px;
	z-index: 10;
	opacity:0;
	overflow: visible;
	white-space: nowrap;
}
#vis3_tx2{
	position: absolute;
	color:#000000;
	font: 500 italic 39px/100% 'Roboto';
	line-height: 39px;
	letter-spacing: 0.03em;
	text-align:left;
	top:132px;
	left:780px;
	width:820px;
	height:40px;
	z-index: 10;
	opacity:0;
	overflow: visible;
	white-space: nowrap;
}
#vis3_tx3{
	position: absolute;
	background-image:url("../../images/alpineflagship/perfect_logo2.png");
	background-repeat: no-repeat;
	background-position: right top;
	background-size: 85%;
	left:895px;
	top:190px;
	width:700px;
	height:73px;
	z-index: 10;
	opacity:0;
}

@media screen and (max-width: 1400px) {
	/* 1400px以下に適用されるCSS（タブレット用） */
	#visWrap {
		height:56.214285714285714vw;
	}
	#navi {
		top:49.142857142857143vw;
		width:100vw;
		height:5.071428571428571vw;
	}
	#menu {
		width:75.571428571428571vw;
	}
	
	#vis1_tx2{
		font: normal 2.464285714285714vw/150% 'Noto Serif JP', sans-serif;
	}
	#vis2_tx1{
		font: 500 italic 2.785714285714286vw/100% 'Roboto';
		line-height: 2.785714285714286vw;
	}
	#vis2_tx2{
		font: normal 500 italic 2.785714285714286vw/100% 'Roboto';
		line-height: 2.785714285714286vw;
	}
	#vis3_tx1{
		font: normal 500 italic 2.785714285714286vw/100% 'Roboto';
		line-height: 2.785714285714286vw;
	}
	#vis3_tx2{
		font: normal 500 italic 2.785714285714286vw/100% 'Roboto';
		line-height: 2.785714285714286vw;
	}
}

@media (max-width: 767px) {
	#visWrap {
		display: none;
	}
}