.g-nav_flagship {
    max-width: 1100px;
    margin: 0 auto !important;
    padding: 10px 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.g-nav_flagship li {
    display: block;
    width: 100%;
    padding: 12px;
}

.g-nav_flagship a {
    color: #fff !important;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 2px solid #fff;
    display: block;
    padding-bottom: 6px;
}

.g-nav_flagship a:hover {
    opacity: 0.8;
}


@media screen and (min-width: 768px) {
    .nav {
        height: 70px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(108,108,108,.5);
    }
}