
/* Mega Header */

.dropdown-product-cat {
  overflow: hidden;
  width: 1170px !important;
}

.dropdown-product-cat .dropbtn-cat {
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  outline: none;
  color: #212121;
  padding: 8px 0;
  display: inline-block;
}

.dropdown-product-cat:hover .dropbtn-cat {
  color: #00549a;
}

.dropbtn-cat .arrow {
  display: none;
  margin-left: 6px;
}
.dropbtn-cat .arrow svg {
  width: 10px;
  height: 10px;
  fill: #00549a;
}

.dropdown-product-cat:hover .dropbtn-cat .arrow {
  display: inline;
}

/* dropdown-product-cat content (hidden by default) */
.dropdown-product-cat-content {
  display: none;
  position: absolute;
  width: 67%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  margin: 40px 30px 40px 60px;
  border-left: 1px solid rgb(225, 224, 224);
  columns: 5;
  column-fill: auto;
  column-gap: 50px;
  height: calc(100% - 80px);
}

/* Show the dropdown-product-cat menu on hover */
.dropdown-product-cat:hover .dropdown-product-cat-content {
  display: block;
}

.dropdown-product-cat-content-wrapper {
  max-width: 1170px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 10px 10px 60px;
  font-size: 14px;
}

.dropdown-product-cat-content-wrapper h6 {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 0;
  color: #212121;
  letter-spacing: 0;
  line-height: 1;
}

.dropdown-product-cat-content-wrapper a {
  color: #212121;
}
.dropdown-product-cat-content-wrapper a:hover {
  color: #00549a;
}

.subcat-wrapper {
-webkit-column-break-inside: avoid;
page-break-inside: avoid;
break-inside: avoid;
margin-bottom: 20px;
min-width: 160px;
}
.subcat-wrapper h6 {
  margin-bottom: 5px;
  line-height: 1.2;
}

ul.subcat-list {
  font-size: 15px;
  margin-bottom: 20px;
}
ul.subcat-list li {
  margin: 2px 0;
}
ul.subcat-list li a {
  color: #212121;
  font-size: 13px;
}
ul.subcat-list li a:hover {
  color: #00549a;
}



