main img {
    max-width: 100%;
}


/* Completo */

.p-index {
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 1.3;
    color: #222;
  
    margin-top: 76px;
  }
  
  .p-index .anchor
  {
    
    position: relative;
  }
  
  .banner-open .p-index {
    margin-top: 114px;
  }
  
  .p-index h2.o-title__text {
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.85px;
    padding-left: 12px;
    padding-right: 12px;
  }
  
  @media (max-width: 1005px) {
    .p-index {
      margin-top: 43px;
    }
  
    .banner-open .p-index {
      margin-top: 82px;
    }
  }
  
  .p-index ul li, .p-index ol li {
    list-style: none;
  }
  .p-index *, .p-index *:before, .p-index *:after {
    box-sizing: border-box;
  }
  .p-index h1, .p-index h2, .p-index h3, .p-index h4, .p-index h5, .p-index h6 {
    font-weight: normal;
  }
  .p-index header, .p-index nav, .p-index main, .p-index footer, .p-index small, .p-index button, .p-index label, .p-index picture {
    display: block;
  }
  .p-index a, .p-index button {
    cursor: pointer;
    text-decoration: none;
    max-width: 100%;
  }
  .p-index img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .p-index button:hover, .p-index button:active {
    outline: 0;
    text-decoration: none;
  }
  @media (min-width: 768px) {
    .p-index button:hover, .p-index button:active {
      opacity: 0.8;
    }
  }
  .p-index a:hover, .p-index a:active {
    outline: 0;
    text-decoration: none;
  }
  @media (min-width: 768px) {
    .p-index a:hover, .p-index a:active {
      opacity: 0.8;
    }
  }
  .p-index input, .p-index button, .p-index textarea, .p-index select {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
    display: block;
  }
  .p-index textarea {
    resize: vertical;
  }
  .p-index input[type=checkbox],
  .p-index input[type=radio] {
    display: none;
  }
  .p-index input[type=submit],
  .p-index input[type=button],
  .p-index label,
  .p-index button,
  .p-index select {
    cursor: pointer;
  }
  .p-index select::-ms-expand {
    display: none;
  }
  .p-index ::placeholder {
    color: #ccc;
  }
  .p-index .wrapper {
    position: relative;
  }
  .p-index .bold {
    font-weight: bold;
  }
  .p-index .center {
    text-align: center;
  }
  .p-index .text_left {
    text-align: left;
  }
  .p-index .text_right {
    text-align: right;
  }
  @media (min-width: 768px) {
    .p-index .display_to_mq {
      display: none;
    }
  }
  @media (min-width: 1025px) {
    .p-index .display_to_mq_m {
      display: none;
    }
  }
  .p-index .display_mq__inline {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index .display_mq__inline {
      display: inline;
    }
  }
  .p-index .display_mq__block {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index .display_mq__block {
      display: block;
    }
  }
  .p-index .display_mq_m__inline {
    display: none;
  }
  @media (min-width: 1025px) {
    .p-index .display_mq_m__inline {
      display: inline;
    }
  }
  .p-index .display_mq_m__block {
    display: none;
  }
  @media (min-width: 1025px) {
    .p-index .display_mq_m__block {
      display: block;
    }
  }
  .p-index .display_mq_l__inline {
    display: none;
  }
  @media (min-width: 1280px) {
    .p-index .display_mq_l__inline {
      display: inline;
    }
  }
  .p-index .display_mq_l__block {
    display: none;
  }
  @media (min-width: 1280px) {
    .p-index .display_mq_l__block {
      display: block;
    }
  }
  .p-index .relative_wrap {
    position: relative;
  }
  .p-index .inline_block {
    display: inline-block;
    max-width: 100%;
  }
  .p-index .pt1em {
    padding-top: 1em;
  }
  .p-index .pr1em {
    padding-right: 1em;
  }
  .p-index .pb1em {
    padding-bottom: 1em;
  }
  .p-index .pl1em {
    padding-left: 1em;
  }
  .p-index .mt1em {
    margin-top: 1em;
  }
  .p-index .mr1em {
    margin-right: 1em;
  }
  .p-index .mb1em {
    margin-bottom: 1em;
  }
  .p-index .ml1em {
    margin-left: 1em;
  }
  .p-index .text_indent {
    display: block;
    padding-left: 1em;
    text-indent: -1em;
  }
  .p-index .clickable {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    .p-index .clickable:hover {
      opacity: 0.8;
    }
  }
  
  .l-main__wrap {
    overflow: hidden;
    font-weight: 300;
  }
  .l-main__wrap img {
    display: block;
  }
  .l-main__learn_more_box {
    width: 84.8%;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .l-main__learn_more_box {
      width: 100%;
      max-width: 1190px;
      padding: 0 20px;
    }
  }
  
  @media (min-width: 768px) {
    .o-box__ps_list {
      position: relative;
    }
  }
  .o-box__fixed_contact {
    display: block;
    position: fixed;
    right: 0;
    bottom: 20px;
    z-index: 1400;
    max-width: 198px;
    display: none;
  }
  @media (min-width: 768px) {
    .o-box__fixed_contact {
      max-width: 563px;
    }
  }
  .o-box__fade_item {
    opacity: 0;
    transform: translateY(50px);
    transition: transform 0.9s, opacity 0.9s;
  }
  @media (min-width: 768px) {
    .o-box__fade_item {
      transform: translateY(100px);
    }
  }
  .o-box__fade_item.is-fade_active {
    opacity: 1;
    transform: translateY(0);
  }
  .o-box__nav_flagship {
    background-color: #505050;
  }
  @media (min-width: 768px) {
    .o-box__nav_flagship {
      background-color: rgba(95, 95, 95, 0.7);
    }
  }
  @media (min-width: 768px) {
    .o-box__nav_flagship {
      padding: 0 20px;
    }
  }
  @media (min-width: 768px) {
    .o-box__nav_flagship.is-top_sp {
      display: none;
    }
  }
  .o-box__nav_flagship.is-mt_type1 {
    margin-top: 75px;
  }
  @media (min-width: 768px) {
    .o-box__nav_flagship.is-mt_type1 {
      margin-top: 300px;
    }
  }
  .o-box__nav_flagship.is-mt_type2 {
    margin-top: 23px;
  }
  @media (min-width: 768px) {
    .o-box__nav_flagship.is-mt_type2 {
      margin-top: 40px;
    }
  }
  .o-box__nav_flagship.is-mb_type1 {
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    .o-box__nav_flagship.is-mb_type1 {
      margin-bottom: 64px;
    }
  }
  @media (min-width: 768px) {
    .o-box__nav_learn_more {
      padding: 0 20px;
    }
  }
  .o-box__nav_learn_more.is-bottom_nav {
    margin-top: 40px;
  }
  @media (min-width: 768px) {
    .o-box__nav_learn_more.is-bottom_nav {
      margin-top: 60px;
    }
  }
  .o-box__bottom_care {
    max-width: 375px;
    margin: 10px auto 0;
    padding: 0 35px;
  }
  @media (min-width: 768px) {
    .o-box__bottom_care {
      max-width: none;
      margin-top: 20px;
      margin-left: 21.35%;
      margin-right: 0;
      padding: 0;
    }
  }
  .o-box__bottom_care img {
    width: 285px;
  }
  @media (min-width: 768px) {
    .o-box__bottom_care img {
      width: 571px;
    }
  }
  
  .o-list__top_intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    .o-list__top_intro {
      max-width: 1408px;
      margin-left: auto !important;
      flex-wrap: nowrap;
      justify-content: space-evenly;
    }
  }
  .o-list__top_intro > li {
    margin-top: 8px;
    flex: 0 0 49.06%;
  }
  @media (min-width: 768px) {
    .o-list__top_intro > li {
      margin-top: 42px;
      flex: 0 0 22.58%;
    }
  }
  .o-list__nav_flagship {
    max-width: 366px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    .o-list__nav_flagship {
      max-width: 1058px;
    }
  }
  .o-list__nav_flagship > li {
    flex: 0 0 31.69%;
  }
  @media (min-width: 768px) {
    .o-list__nav_flagship > li {
      flex: 0 0 31.75%;
    }
  }
  .o-list__nav_flagship > li > a {
    display: block;
  }
  .o-list__nav_flagship.is-l_us {
    max-width: 250px;
  }
  @media (min-width: 768px) {
    .o-list__nav_flagship.is-l_us {
      max-width: 722px;
    }
  }
  .o-list__nav_flagship.is-l_us > li {
    flex: 0 0 47.55%;
  }
  @media (min-width: 768px) {
    .o-list__nav_flagship.is-l_us > li {
      flex: 0 0 47.62%;
    }
  }
  .o-list__nav_learn_more {
    max-width: 366px;
    margin: 0 auto !important;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    .o-list__nav_learn_more {
      max-width: 965px;
    }
  }
  .o-list__nav_learn_more > li {
    flex: 0 0 31.69%;
  }
  @media (min-width: 768px) {
    .o-list__nav_learn_more > li {
      flex: 0 0 29.84%;
    }
  }
  .o-list__nav_learn_more > li > a {
    display: block;
  }
  @media (-ms-high-contrast: none) and (min-width: 768px) {
    .o-list__top_intro {
      justify-content: space-around;
    }
  }
  .o-single_img__logo {
    width: 100px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .o-single_img__logo {
      width: 240px;
    }
  }
  .o-single_img__pi_thumb {
    max-width: 750px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .o-single_img__pi_thumb {
      max-width: 1560px;
      padding: 0 20px;
    }
  }
  @media (min-width: 768px) {
    .o-single_img__pi_thumb.is-to_mq {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .o-single_img__lm_thumb {
      max-width: 1190px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }
  @media (min-width: 768px) {
    .o-single_img__lm_thumb.is-to_mq {
      display: none;
    }
  }
  .o-single_img__lm_thumb.is-mt_type1 {
    margin-top: 18px;
  }
  @media (min-width: 768px) {
    .o-single_img__lm_thumb.is-mt_type1 {
      margin-top: 32px;
    }
  }
  .o-single_img__lm_thumb.is-mt_type2 {
    margin-top: 40px;
  }
  @media (min-width: 768px) {
    .o-single_img__lm_thumb.is-mt_type2 {
      margin-top: 90px;
    }
  }
  .o-single_img__lm_thumb.is-mt_type3 {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    .o-single_img__lm_thumb.is-mt_type3 {
      margin-top: 48px;
    }
  }
  .o-single_img__lm_thumb.is-thumb19 {
    margin-top: 16px;
  }
  @media (min-width: 768px) {
    .o-single_img__lm_thumb.is-thumb19 {
      max-width: 1020px;
      margin: 24px auto 0;
      padding: 0 20px;
    }
  }
  .o-single_img__perfect_logo {
    max-width: 237px;
    padding-top: 10px;
  }
  @media (min-width: 768px) {
    .o-single_img__perfect_logo {
      max-width: 474px;
      padding-top: 15px;
    }
  }
  
  .o-text_box__base {
    padding-top: 15px;
    font-size: 9.56px;
    line-height: calc(31/19.13);
  }
  @media (min-width: 768px) {
    .o-text_box__base {
      padding-top: 20px;
      font-size: 15.59px;
    }
  }
  .o-text_box__base span.is-sup {
    position: relative;
    top: -0.25em;
    font-size: 8px;
  }
  @media (min-width: 768px) {
    .o-text_box__base span.is-sup {
      top: -0.5em;
      font-size: 10px;
    }
  }
  .o-text_box__base span.is-indent_half {
    display: block;
    padding-left: 0.5em;
    text-indent: -0.5em;
  }
  .o-text_box__base.is-c_wh {
    color: #fff;
  }
  @media (min-width: 768px) {
    .o-text_box__base.is-top_spec {
      width: 41%;
      max-width: 550px;
      margin-left: 30px;
    }
  }
  .o-text_box__base.is-ps_top {
    padding: 15px 7.6% 0;
  }
  @media (min-width: 768px) {
    .o-text_box__base.is-ps_top {
      max-width: 560px;
      padding: 10px 0 0;
    }
  }
  .o-text_box__base.is-commit_text {
    padding: 10px 7.6% 0;
  }
  @media (min-width: 768px) {
    .o-text_box__base.is-commit_text {
      padding: 24px 0 0;
      max-width: 550px;
    }
  }
  .o-text_box__base.is-font_type1 {
    font-size: 8.86px;
    padding-top: 12px;
    line-height: calc(29/17.72);
  }
  @media (min-width: 768px) {
    .o-text_box__base.is-font_type1 {
      font-size: 15.59px;
      padding-top: 18px;
    }
  }
  .o-text_box__sub_title {
    padding: 0 7.6%;
    margin-top: 20px;
    font-size: 12.75px;
    font-weight: bold;
  }
  @media (min-width: 768px) {
    .o-text_box__sub_title {
      padding: 0;
      margin-top: 15px;
      font-size: 23.56px;
    }
  }
  .o-text_box__ps_list {
    font-size: 9.56px;
    padding: 8px 7.6% 0;
    line-height: calc(31/19.13);
  }
  @media (min-width: 768px) {
    .o-text_box__ps_list {
      font-size: 14.88px;
      padding: 15px 0 0;
      margin-left: 30px;
      max-width: 550px;
      width: 41%;
    }
  }
  @media (min-width: 1025px) {
    .o-text_box__ps_list {
      width: 41%;
    }
  }
  
  .o-btn__top_slide {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 31.75%;
    height: 100%;
  }
  .o-btn__top_slide img {
    display: block;
  }
  .o-btn__top_slide.is-center {
    left: 50%;
    transform: translateX(-50%);
  }
  .o-btn__top_slide.is-right {
    left: auto;
    right: 0;
  }
  .o-btn__top_slide.is-l_us_left {
    left: 16.66%;
  }
  .o-btn__top_slide.is-l_us_right {
    left: auto;
    right: 16.66%;
  }
  
  @media (min-width: 768px) {
    .o-flex_box__pi_care {
      display: flex;
      flex-wrap: wrap;
      margin-left: 255px;
    }
  }
  
  .o-section__intro {
    padding: 0 7.6% 30px;
  }
  @media (min-width: 768px) {
    .o-section__intro {
      max-width: 1640px;
      margin: 0 auto;
      padding: 0 0 88px;
    }
  }
  @media (min-width: 768px) {
    .o-section__spec {
      max-width: 1568px;
      margin: 0 auto;
      background-color: #000;
    }
  }
  .o-section__ps {
    background-image: url(../../images/alpineflagship/perfect_bg_sp.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 225px;
  }
  @media (min-width: 768px) {
    .o-section__ps {
      max-width: 1568px;
      margin: 0 auto;
      background-image: url(../../images/alpineflagship/perfect_bg_pc.png);
      background-size: 1566px auto;
      position: relative;
      padding-top: 38px;
      padding-bottom: 220px;
    }
  }
  .o-section__commitment {
    margin-top: 40px;
  }
  @media (min-width: 768px) {
    .o-section__commitment {
      max-width: 1568px;
      margin: 0 auto;
    }
  }
  .o-section__product_info {
    padding: 53px 7.6% 0;
  }
  @media (min-width: 768px) {
    .o-section__product_info {
      max-width: 1568px;
      margin: 0 auto;
      padding: 160px 0 0;
      position: relative;
    }
  }
  
  @media (min-width: 768px) {
    .o-slider.is-top_sp {
      display: none;
    }
  }
  .o-slider.is-top_sp .swiper-pagination-bullet {
    background-color: #969696;
    width: 50px;
    height: 7px;
    border-radius: 0;
    opacity: 1;
    transform: skewX(-20deg);
  }
  .o-slider.is-top_sp .swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
  }
  
  .o-title__bk_band {
    display: inline-block;
    background-color: #000;
    color: #fff;
    font-size: calc(29.76px/2);
    font-weight: bold;
    margin-top: 32px !important;
    padding: 5px 15px;
    letter-spacing: 2px;
  }
  @media (min-width: 768px) {
    .o-title__bk_band {
      font-size: 28.96px;
      margin-top: 62px !important;
      letter-spacing: 3px;
      padding: 6px 40px;
    }
  }
  .o-title__bk_band.is-l_char {
    padding: 5px 0;
    letter-spacing: 0;
  }
  @media (min-width: 768px) {
    .o-title__bk_band.is-l_char {
      padding: 6px 40px;
      letter-spacing: 3px;
    }
  }
  .o-title__bk_band.is-l_us1 {
    padding: 0;
    background-color: transparent;
    max-width: 233px;
  }
  @media (min-width: 768px) {
    .o-title__bk_band.is-l_us1 {
      max-width: 435px;
    }
  }
  .o-title__bk_band.is-l_us2 {
    padding: 0;
    background-color: transparent;
    max-width: 305px;
  }
  @media (min-width: 768px) {
    .o-title__bk_band.is-l_us2 {
      max-width: 570px;
    }
  }
  .o-title__bk_band.is-l_us3 {
    padding: 0;
    background-color: transparent;
    max-width: 206px;
  }
  @media (min-width: 768px) {
    .o-title__bk_band.is-l_us3 {
      max-width: 383px;
    }
  }
  .o-title__bk_band.is-mt_type1 {
    margin-top: 89px;
  }
  @media (min-width: 768px) {
    .o-title__bk_band.is-mt_type1 {
      margin-top: 194px;
    }
  }
  .o-title__bk_band.is-mt_type2 {
    margin-top: 65px;
  }
  @media (min-width: 768px) {
    .o-title__bk_band.is-mt_type2 {
      margin-top: 230px;
    }
  }
  .o-title__learn_more_sub {
    font-size: calc(21.97px/2);
    font-weight: bold;
    margin-top: 12px;
  }
  @media (min-width: 768px) {
    .o-title__learn_more_sub {
      font-size: 21.97px;
      margin-top: 32px;
    }
  }
  .o-title__learn_more_sub.is-mt_type1 {
    margin-top: 25px;
  }
  @media (min-width: 768px) {
    .o-title__learn_more_sub.is-mt_type1 {
      margin-top: 100px;
    }
  }
  .o-title__learn_more_sub.is-mt_type2 {
    margin-top: 25px;
  }
  @media (min-width: 768px) {
    .o-title__learn_more_sub.is-mt_type2 {
      margin-top: 32px;
    }
  }
  .o-title__learn_more_sub.is-mt_type3 {
    margin-top: 45px;
  }
  @media (min-width: 768px) {
    .o-title__learn_more_sub.is-mt_type3 {
      margin-top: 130px;
    }
  }
  
  .p-index__intro_care_text {
    font-size: 6.73px;
    font-weight: 400;
    padding: 6px 0 0;
  }
  @media (min-width: 768px) {
    .p-index__intro_care_text {
      font-size: 12.05px;
      padding: 12px 30px 0 0;
      text-align: right;
    }
  }
  @media (min-width: 768px) {
    .p-index__intro_top {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      max-width: 1408px;
      margin-top: 24px;
      margin-left: auto;
    }
  }
  @media (min-width: 768px) {
    .p-index__intro_cnt {
      flex: 0 0 46.87%;
    }
  }
  .p-index__intro_line {
    max-width: 318px;
    margin: 0 auto;
    padding: 30px 0 13px;
  }
  @media (min-width: 768px) {
    .p-index__intro_line {
      width: 13px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-80%);
      padding: 0;
    }
  }
  @media (min-width: 768px) {
    .p-index__intro_line img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .p-index__intro_line_bg {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index__intro_line_bg {
      display: block;
      width: 2px;
      position: absolute;
      top: 88px;
      left: 11px;
      background-color: #7f7f7f;
    }
  }
  .p-index__intro_title {
    max-width: 301px;
  }
  @media (min-width: 768px) {
    .p-index__intro_title {
      max-width: 524px;
    }
  }
  .p-index__intro_title.is-l_us {
    max-width: 213px;
  }
  @media (min-width: 768px) {
    .p-index__intro_title.is-l_us {
      max-width: 359px;
    }
  }
  .p-index__intro_thumb {
    padding-top: 32px;
  }
  @media (min-width: 768px) {
    .p-index__intro_thumb {
      flex: 0 0 46.66%;
      padding-top: 0;
    }
  }
  @media (min-width: 768px) {
    .p-index__intro_bottom {
      max-width: 1640px;
      margin: 0 auto;
    }
  }
  .p-index__spec_wrap {
    background-color: #000;
    padding: 0 7.6% 120px;
    background-image: url(../../images/alpineflagship/spec_bg2_sp.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  @media (min-width: 768px) {
    .p-index__spec_wrap {
      width: 95%;
      max-width: 1368px;
      padding: 73px 0 215px;
      margin-left: auto;
      position: relative;
      background-image: url(../../images/alpineflagship/spec_bg1_pc.png), url(../../images/alpineflagship/spec_bg2_pc.png);
      background-position: left 32px bottom 50px, right bottom;
      background-repeat: no-repeat, no-repeat;
      background-size: 667px auto, 820px auto;
    }
  }
  @media (min-width: 1025px) {
    .p-index__spec_wrap {
      width: 87.24%;
    }
  }
  .p-index__spec_line {
    max-width: 316px;
    margin: 0 auto;
    padding: 22px 0 14px;
  }
  @media (min-width: 768px) {
    .p-index__spec_line {
      width: 17px;
      position: absolute;
      top: 49px;
      left: 0;
      transform: translateX(-80%);
    }
  }
  @media (min-width: 768px) {
    .p-index__spec_line img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .p-index__spec_line_bg {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index__spec_line_bg {
      display: block;
      width: 2px;
      position: absolute;
      top: 231px;
      left: 11px;
      background-color: #b0b0b0;
    }
  }
  .p-index__spec_title {
    max-width: 302px;
  }
  @media (min-width: 768px) {
    .p-index__spec_title {
      max-width: 525px;
      margin-left: 30px;
      width: 39%;
    }
  }
  .p-index__spec_title.is-l_us {
    max-width: 269px;
  }
  @media (min-width: 768px) {
    .p-index__spec_title.is-l_us {
      max-width: 515px;
    }
  }
  .p-index__spec_sub {
    color: #fff;
    font-size: 12.75px;
    font-weight: bold;
    margin-top: 30px;
    padding-top: 100px;
    background-image: url(../../images/alpineflagship/spec_bg1_sp.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 318px auto;
    line-height: calc(32.3/25.51);
    letter-spacing: 2px;
  }
  @media (min-width: 768px) {
    .p-index__spec_sub {
      font-size: 23.56px;
      background: none;
      margin-top: 20px;
      margin-left: 30px;
      padding-top: 0;
      letter-spacing: 3px;
      position: relative;
      padding-bottom: 20px;
      width: 41%;
    }
  }
  @media (min-width: 768px) {
    .p-index__spec_sub::after {
      content: "";
      display: block;
      background-color: rgba(255, 255, 255, 0.5);
      width: 58px;
      height: 7px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .p-index__spec_sub.is-l_us {
    letter-spacing: 0px;
  }
  @media (min-width: 768px) {
    .p-index__spec_sub.is-l_us {
      letter-spacing: 3px;
    }
  }
  .p-index__spec_thumb {
    max-width: 313px;
    padding-top: 15px;
  }
  @media (min-width: 768px) {
    .p-index__spec_thumb {
      max-width: 614px;
      padding-top: 0;
      position: absolute;
      top: 172px;
      width: 44%;
      left: 45%;
    }
  }
  .p-index__learn_more1 {
    display: block;
    padding-top: 19px;
    width: 191px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .p-index__learn_more1 {
      width: 236px;
      margin: 0 0 0 30px;
    }
  }
  .p-index__learn_more1.is-l_us {
    width: 223px;
  }
  @media (min-width: 768px) {
    .p-index__learn_more1.is-l_us {
      width: 350px;
    }
  }
  .p-index__ps_top_cnt {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 15px 0 0;
  }
  @media (min-width: 768px) {
    .p-index__ps_top_cnt {
      max-width: 790px;
      width: 75%;
      margin-left: auto;
      padding: 26px 22px 300px 22px;
      border-top: 1px solid #9a9b99;
      border-left: 1px solid #9a9b99;
    }
  }
  @media (min-width: 1025px) {
    .p-index__ps_top_cnt {
      width: 51%;
    }
  }
  .p-index__ps_line {
    max-width: 312px;
    margin: 0 auto;
    padding-bottom: 12px;
  }
  @media (min-width: 768px) {
    .p-index__ps_line {
      width: 16px;
      padding-bottom: 0;
      position: absolute;
      top: 43px;
      left: 12.75%;
      transform: translateX(-80%);
    }
  }
  .p-index__ps_line_bg {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index__ps_line_bg {
      display: block;
      width: 2px;
      position: absolute;
      top: 170px;
      left: 11px;
      background-color: #686868;
    }
  }
  .p-index__ps_title {
    max-width: 337px;
    margin-left: 7.6%;
  }
  @media (min-width: 768px) {
    .p-index__ps_title {
      max-width: 525px;
      margin: 0;
    }
  }
  .p-index__ps_title.is-l_us {
    max-width: 325px;
  }
  @media (min-width: 768px) {
    .p-index__ps_title.is-l_us {
      max-width: 621px;
    }
  }
  .p-index__perfect_logo {
    max-width: 237px;
    margin-left: 7.6%;
    padding-top: 4px;
  }
  @media (min-width: 768px) {
    .p-index__perfect_logo {
      max-width: 474px;
      margin: 0;
      padding-top: 15px;
      padding-bottom: 24px;
      position: relative;
    }
  }
  @media (min-width: 768px) {
    .p-index__perfect_logo::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 58px;
      height: 7px;
      background-color: #6b6b6b;
    }
  }
  .p-index__ps_thumb1 {
    max-width: 244px;
    margin: 24px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__ps_thumb1 {
      max-width: 456px;
      margin: 0;
      position: relative;
    }
  }
  @media (min-width: 768px) {
    .p-index__ps_thumb1 img {
      position: absolute;
      width: 100%;
      top: 90px;
      left: 0;
    }
  }
  @media (min-width: 768px) {
    .p-index__ps_list {
      width: 87.25%;
      max-width: 1372px;
      margin-left: auto !important;
    }
  }
  .p-index__ps_list > li {
    margin-top: 40px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list > li {
      margin-top: 112px;
    }
  }
  @media (min-width: 1280px) {
    .p-index__ps_list > li {
      margin-top: 40px;
    }
  }
  @media (min-width: 768px) {
    .p-index__ps_list > li:nth-child(2) {
      margin-top: 112px;
    }
  }
  @media (min-width: 768px) {
    .p-index__ps_list > li:nth-child(3) {
      margin-top: 112px;
    }
  }
  .p-index__ps_list_title {
    max-width: 290px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_title {
      max-width: 554px;
    }
  }
  .p-index__ps_list_title.is-num2 {
    max-width: 301px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_title.is-num2 {
      max-width: 613px;
    }
  }
  .p-index__ps_list_title.is-num3 {
    max-width: 300px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_title.is-num3 {
      max-width: 613px;
    }
  }
  .p-index__ps_list_sub {
    font-size: 12.75px;
    font-weight: bold;
    margin-top: 16px;
    padding: 0 7.6%;
    line-height: calc(32.3/25.51);
    letter-spacing: 2px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_sub {
      font-size: 23.56px;
      margin-top: 24px;
      margin-left: 30px;
      padding: 0;
      letter-spacing: 3px;
    }
  }
  .p-index__ps_list_sub span.is-sup {
    position: relative;
    top: -0.75em;
    font-size: 8px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_sub span.is-sup {
      top: -1em;
      font-size: 14px;
    }
  }
  .p-index__ps_list_sub.is-num01 {
    letter-spacing: -1px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_sub.is-num01 {
      letter-spacing: 0px;
    }
  }
  .p-index__ps_list_sub.is-l_us {
    font-size: 13.46px;
    letter-spacing: 0px;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_sub.is-l_us {
      font-size: 25.51px;
      letter-spacing: 1px;
    }
  }
  .p-index__ps_list_sub.is-l_us_num02 {
    font-size: 13.46px;
    letter-spacing: 0px;
    white-space: nowrap;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_sub.is-l_us_num02 {
      font-size: 25.51px;
      letter-spacing: 1px;
    }
  }
  @media (min-width: 768px) {
    .p-index__ps_list_sub.is-mt_type1 {
      margin-top: 60px;
    }
  }
  .p-index__ps_list_thumb {
    max-width: 320px;
    margin: 20px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_thumb {
      margin: 0;
      position: absolute;
    }
  }
  .p-index__ps_list_thumb.is-num01_thumb {
    width: 85.33%;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_thumb.is-num01_thumb {
      width: 42%;
      max-width: 567px;
      top: 20px;
      left: 48%;
    }
  }
  @media (min-width: 1280px) {
    .p-index__ps_list_thumb.is-num01_thumb {
      left: 45%;
    }
  }
  .p-index__ps_list_thumb.is-text_bottom {
    max-width: 239px;
    margin: 20px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_thumb.is-text_bottom {
      width: 34%;
      max-width: 459px;
      top: 0;
      left: 48%;
    }
  }
  @media (min-width: 1280px) {
    .p-index__ps_list_thumb.is-text_bottom {
      left: 45%;
    }
  }
  .p-index__ps_list_thumb.is-num03_thumb {
    max-width: 200px;
    margin: 10px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__ps_list_thumb.is-num03_thumb {
      width: 36%;
      max-width: 490px;
      margin: 0;
      top: 30px;
      left: 54%;
    }
  }
  @media (min-width: 1280px) {
    .p-index__ps_list_thumb.is-num03_thumb {
      left: 50.5%;
    }
  }
  @media (min-width: 1440px) {
    .p-index__ps_list_thumb.is-num03_thumb {
      top: -15px;
    }
  }
  .p-index__learn_more3 {
    display: block;
    max-width: 189px;
    margin: 25px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__learn_more3 {
      max-width: 235px;
      margin: 20px 0 0 30px;
    }
  }
  .p-index__learn_more3.is-l_us {
    max-width: 166px;
  }
  @media (min-width: 768px) {
    .p-index__learn_more3.is-l_us {
      max-width: 255px;
    }
  }
  .p-index__learn_more4 {
    display: block;
    max-width: 200px;
    margin: 20px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__learn_more4 {
      max-width: 250px;
      margin: 20px 0 0 30px;
    }
  }
  .p-index__learn_more4.is-l_us {
    max-width: 251px;
  }
  @media (min-width: 768px) {
    .p-index__learn_more4.is-l_us {
      max-width: 401px;
    }
  }
  .p-index__learn_more5 {
    display: block;
    max-width: 193px;
    margin: 20px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__learn_more5 {
      max-width: 236px;
      margin: 20px 0 0 30px;
    }
  }
  .p-index__learn_more5.is-l_us {
    max-width: 170px;
  }
  @media (min-width: 768px) {
    .p-index__learn_more5.is-l_us {
      max-width: 257px;
    }
  }
  .p-index__learn_more6 {
    display: block;
    max-width: 229px;
    margin: 20px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__learn_more6 {
      max-width: 283px;
      margin: 20px 0 0 30px;
    }
  }
  .p-index__learn_more6.is-l_us {
    max-width: 225px;
  }
  @media (min-width: 768px) {
    .p-index__learn_more6.is-l_us {
      max-width: 426px;
    }
  }
  @media (min-width: 768px) {
    .p-index__commit_wrap {
      background-image: url(../../images/alpineflagship/mother_bg_pc.png);
      background-position: center top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: relative;
      padding-top: 38px;
    }
  }
  @media (min-width: 768px) {
    .p-index__commit_box {
      width: 90%;
      max-width: 790px;
      margin-left: auto;
      padding: 26px 0 428px 22px;
      border-top: 1px solid #898989;
      border-left: 1px solid #898989;
      background-color: rgba(255, 255, 255, 0.7);
      position: relative;
    }
  }
  .p-index__commit_title {
    background-image: url(../../images/alpineflagship/mother_bg_sp.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: calc(676vw/7.5);
    position: relative;
  }
  @media (min-width: 768px) {
    .p-index__commit_title {
      background: none;
      height: auto;
    }
  }
  .p-index__commit_title_thumb {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 15px 0;
  }
  @media (min-width: 768px) {
    .p-index__commit_title_thumb {
      position: relative;
      background-color: transparent;
      padding: 0;
    }
  }
  .p-index__commit_title_thumb img {
    display: block;
    max-width: 311px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .p-index__commit_title_thumb img {
      max-width: 499px;
      margin: 0;
    }
  }
  .p-index__commit_title_thumb.is-l_us img {
    max-width: 231px;
    margin-left: 7.6%;
    margin-right: 0;
  }
  @media (min-width: 768px) {
    .p-index__commit_title_thumb.is-l_us img {
      max-width: 440px;
      margin: 0;
    }
  }
  .p-index__item_thumb1 {
    max-width: 181px;
    margin: 15px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__item_thumb1 {
      max-width: 464px;
      margin: 0;
      position: absolute;
      bottom: 18px;
      left: 60px;
    }
  }
  .p-index__learn_more7 {
    display: block;
    max-width: 230px;
    margin: 25px auto 0;
  }
  @media (min-width: 768px) {
    .p-index__learn_more7 {
      max-width: 287px;
      margin: 20px 0 0 0;
    }
  }
  .p-index__learn_more7.is-l_us {
    max-width: 214px;
  }
  @media (min-width: 768px) {
    .p-index__learn_more7.is-l_us {
      max-width: 327px;
    }
  }
  .p-index__pi_line {
    max-width: 312px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .p-index__pi_line {
      max-width: 12px;
      margin: 0;
      position: absolute;
      top: 160px;
      left: 12.75%;
      transform: translateX(-80%);
    }
  }
  .p-index__pi_line_bg {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index__pi_line_bg {
      display: block;
      width: 2px;
      position: absolute;
      top: 181px;
      left: 11px;
      background-color: #686868;
    }
  }
  .p-index__pi_line_care {
    font-size: 6.73px;
    font-weight: 500;
    padding-top: 10px;
  }
  @media (min-width: 768px) {
    .p-index__pi_line_care {
      font-size: 12.05px;
      padding-top: 20px;
    }
  }
  .p-index__pi_title {
    display: none;
  }
  @media (min-width: 768px) {
    .p-index__pi_title {
      display: block;
      width: 494px;
      margin-right: 25px;
    }
  }
  .p-index__product_info_list {
    max-width: 318px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list {
      width: 85.24%;
      max-width: 1311px;
      margin: 0 0 0 auto !important;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .p-index__product_info_list > li {
    position: relative;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li {
      flex: 0 0 47.29%;
    }
  }
  .p-index__product_info_list > li a {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 99px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li a {
      position: relative;
      width: 109px;
    }
  }
  .p-index__product_info_list > li a.is-item_thumb2 {
    bottom: 14%;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li a.is-item_thumb2 {
      bottom: -13px;
      margin: 0 auto;
    }
  }
  @media (min-width: 1280px) {
    .p-index__product_info_list > li a.is-item_thumb2 {
      margin-left: 186px;
    }
  }
  .p-index__product_info_list > li a.is-item_thumb3 {
    bottom: 30%;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li a.is-item_thumb3 {
      bottom: 10px;
      margin: 0 auto;
    }
  }
  @media (min-width: 1280px) {
    .p-index__product_info_list > li a.is-item_thumb3 {
      margin-left: 160px;
    }
  }
  .p-index__product_info_list > li a.is-item_thumb4 {
    bottom: 30%;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li a.is-item_thumb4 {
      bottom: -14px;
      margin: 0 auto;
    }
  }
  @media (min-width: 1280px) {
    .p-index__product_info_list > li a.is-item_thumb4 {
      margin-left: 186px;
    }
  }
  .p-index__product_info_list > li a.is-item_thumb5 {
    bottom: 38%;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li a.is-item_thumb5 {
      bottom: 0;
      margin: 0 auto;
    }
  }
  @media (min-width: 1280px) {
    .p-index__product_info_list > li a.is-item_thumb5 {
      margin-left: 160px;
    }
  }
  .p-index__product_info_list > li a.is-item_thumb6 {
    position: relative;
    margin-top: 15px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li a.is-item_thumb6 {
      margin: 26px auto 0;
    }
  }
  @media (min-width: 1280px) {
    .p-index__product_info_list > li a.is-item_thumb6 {
      margin-left: 498px;
    }
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li:nth-child(2n) {
      flex: 0 0 52.7%;
    }
  }
  .p-index__product_info_list > li.is-item_thumb2 {
    margin-top: 30px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li.is-item_thumb2 {
      margin-top: 48px;
    }
  }
  .p-index__product_info_list > li.is-item_thumb3 {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li.is-item_thumb3 {
      margin-top: 48px;
    }
  }
  .p-index__product_info_list > li.is-item_thumb4 {
    margin-top: 10px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li.is-item_thumb4 {
      margin-top: 120px;
    }
  }
  .p-index__product_info_list > li.is-item_thumb5 {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li.is-item_thumb5 {
      margin-top: 120px;
    }
  }
  .p-index__product_info_list > li.is-item_thumb6 {
    margin-top: 8px;
  }
  @media (min-width: 768px) {
    .p-index__product_info_list > li.is-item_thumb6 {
      margin-top: 150px;
      flex: 0 0 100%;
    }
  }
  .p-index__item_thumb2 {
    max-width: 317px;
  }
  @media (min-width: 768px) {
    .p-index__item_thumb2 {
      max-width: 478px;
      padding: 0 10px;
    }
  }
  @media (min-width: 1280px) {
    .p-index__item_thumb2 {
      padding: 0;
    }
  }
  .p-index__item_thumb3 {
    max-width: 252px;
  }
  @media (min-width: 768px) {
    .p-index__item_thumb3 {
      max-width: 428px;
      padding: 0 10px;
    }
  }
  @media (min-width: 1280px) {
    .p-index__item_thumb3 {
      padding: 0;
    }
  }
  .p-index__item_thumb4 {
    max-width: 297px;
  }
  @media (min-width: 768px) {
    .p-index__item_thumb4 {
      max-width: 447px;
      padding: 0 10px;
    }
  }
  @media (min-width: 1280px) {
    .p-index__item_thumb4 {
      padding: 0;
    }
  }
  .p-index__item_thumb5 {
    max-width: 307px;
  }
  @media (min-width: 768px) {
    .p-index__item_thumb5 {
      max-width: 490px;
      position: relative;
      left: -16px;
      padding: 0 10px;
    }
  }
  @media (min-width: 1280px) {
    .p-index__item_thumb5 {
      padding: 0;
    }
  }
  .p-index__item_thumb6 {
    max-width: 319px;
  }
  @media (min-width: 768px) {
    .p-index__item_thumb6 {
      max-width: 1030px;
      padding: 0 10px;
    }
  }
  @media (min-width: 1280px) {
    .p-index__item_thumb6 {
      padding: 0;
    }
  }
  .p-index__bottom_care {
    max-width: 375px;
    margin: 35px auto 0;
    padding: 0 30px;
  }
  @media (min-width: 768px) {
    .p-index__bottom_care {
      max-width: 571px;
      margin-top: 160px;
      padding: 0;
    }
  }
  .p-index__bottom_care img {
    width: 285px;
  }
  @media (min-width: 768px) {
    .p-index__bottom_care img {
      width: 571px;
    }
  }
  .p-index__footer {
    width: 272px;
    margin: 70px auto 0;
    padding-bottom: 40px;
  }
  @media (min-width: 768px) {
    .p-index__footer {
      width: 544px;
      margin-top: 250px;
    }
  }
  .p-index__footer.is-bottom_care {
    margin-top: 25px;
  }
  @media (min-width: 768px) {
    .p-index__footer.is-bottom_care {
      margin-top: 40px;
    }
  }
  .p-index__footer.is-mt_type1 {
    margin-top: 22px;
  }
  @media (min-width: 768px) {
    .p-index__footer.is-mt_type1 {
      margin-top: 64px;
    }
  }
  
  @media (-ms-high-contrast: none) and (min-width: 768px) {
    .p-index__intro_top {
      justify-content: space-around;
    }
  }
  
  /*# sourceMappingURL=index.css.map */