.tab-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
    
@media screen and (max-width: 1030px) {
    .tab-list {
        flex-flow: nowrap!important;
        justify-content: flex-start!important;
    }
}


.tab-item {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    font-weight: 700;
    align-items: center;
    border-left: 1px solid #D9D9D5;
    display: flex;
    flex-basis: 150px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-bottom: 5px;
    transition: color .5s cubic-bezier(.23,1,.32,1);
}

.tab-item:hover {
    color: #00549a;
}

.tab-item:focus {
    border: 0;
    border-left: 1px solid #D9D9D5;
}

@media only screen and (max-width: 768px) {
    .tab-item {
        min-width: 115px;
        font-size: 11px;
        font-size: .6875rem;
        line-height: 11px;
        line-height: .6875rem;
        letter-spacing: 0;
    }
}

.tab-item:after {
    background-color: #00549a;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    right: -10px;
    opacity: 0;
    transition: opacity .5s cubic-bezier(.23,1,.32,1);
}

@media screen and (max-width: 1030px) {
    .tab-item:after {
        width: 100%;
        left: -1px!important;
        top: 120%!important;
    }
} 

.tab-item.active {
    color: #00549a;
}

.tab-item.active:after {
    opacity: 1;
}