
  @media screen and (min-width: 1005px) {
    .btn-productos-mobile {
      display: none !important;
    }
  } 

  /* The dropdown container */
  .dropdown {
    float: left;
    overflow: hidden;
  }

  @media screen and (max-width: 1004px) {
    .dropdown {
      display: none !important;
    }
  }
  
  /* Dropdown button */
  .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }
  
  /* Add a red background color to navbar links on hover */
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  /* Dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content-wrapper {
    max-width: 1170px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    min-height: 600px;
    padding: 30px 0;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
 