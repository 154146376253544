.feature-prod-slider .slick-slider {
    display: flex;
    justify-content: center;
}

.feature-prod-slider .slick-list {
    max-width: 1080px;
}

.feature-prod-slider .slide-info {
    /* transform: translate(0px, 70px); */
    opacity: 0;
    transition: .6s cubic-bezier(.23,1,.32,1);
}
.feature-prod-slider .slick-center .slide-info {
    /* transform: translate(0px, 0px); */
    opacity: 1;
    transition: .6s cubic-bezier(.23,1,.32,1);

}

.feature-prod-slider .slide-img {
    transform: scale(.70);
}
.feature-prod-slider .slick-center .slide-img {
    transform: scale(.92);
}
.feature-prod-slider .slick-center .content {
    border: 1px solid transparent;
}
.feature-prod-slider .slick-center .content:hover {
    border-color: #e2e2de;
}
.feature-prod-slider .slick-center .content:hover .slide-img {
    transform: scale(1);
}

