.instagram-container {
    width: 100%;
}

.row-collage {
    display: flex;
}

.wrapper-sm {
    width: 7.14285%;
    height: 50%;
    padding-top: 100%;
    position: relative;
}

.wrapper-md {
    width: 7.14285%;
    height: 50%;
    padding-top: 100%;
    position: relative;
}

.wrapper-lg {
    width: 14.28571%;
    height: 50%;
    padding-top: 100%;    
    position: relative;
}

.wrapper-sm::before, .wrapper-md::before, .wrapper-lg::before {

} 

.box-img-sm {
    width: 50%;
    height: 50%;
}



.footer-instagram {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

